<template>
  <!-- 发起调整客户归属 -->
  <div class="type_area">
    <div class="content_box">
      <div class="top_title">
        <span class="title">申请人信息</span>
      </div>
      <div class="form_box">
        <el-form label-width="120px" label-position="top" :model="formData">
          <el-form-item class="form_item" label="申请人">
            <el-input v-model="formData.name" clearable placeholder="默认为当前登录人"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="申请人所在部门">
            <el-input v-model="formData.region" disabled placeholder="默认带出"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="所属省办">
            <el-input v-model="formData.type" disabled placeholder="默认带出"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="所属省">
            <el-input v-model="formData.type" disabled placeholder="默认带出"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="所属市">
            <el-input v-model="formData.type" disabled placeholder="默认带出"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="所属区域">
            <el-input v-model="formData.type" disabled placeholder="默认带出"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="所属职务">
            <el-input v-model="formData.type" disabled placeholder="默认带出"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="入职日期">
            <el-date-picker
              style="width: 100%;"
              value-format="yyyy-MM-dd"
              v-model="formData.type"
              type="date"
              placeholder="请选择">
            </el-date-picker>
          </el-form-item>
          <el-form-item class="form_item" label="生效日期">
            <el-date-picker
              style="width: 100%;"
              value-format="yyyy-MM-dd"
              v-model="formData.type"
              type="date"
              placeholder="请选择">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="top_title">
        <span class="title">客户明细</span>
        <div class="btn_box">
          <div class="btn import">导入客户</div>
          <div class="btn delete">批量删除</div>
        </div>
      </div>
      <div class="table_mode">
        <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" @selection-change="handleSelectionChange" stripe style="width: 100%">
          <el-table-column type="selection" width="55" align="center" fixed="left"></el-table-column>
          <el-table-column prop="companyName" label="客户编号" min-width="120" :show-overflow-tooltip="true" align="center" fixed="left"></el-table-column>
          <el-table-column prop="businessDepart" label="客户名称" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="地址" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="业务员" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="联系人" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="联系电话" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="企业类型" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="memberLevel" label="注册时间" min-width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="address" label="操作 " width="80" align="center">
            <template slot-scope="scope">
              <div class="table_btn">
                <div class="btn_item">移除</div>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="nodata_box">
            <img src="@/assets/images/index/nodata.png"/>
            <span class="txt">暂无数据哦~</span>
          </div>
        </el-table>
      </div>
      <div class="pagination_box">
        <el-pagination
          background
          layout="prev, pager, next, sizes, total"
          :page-sizes="[10, 20, 30, 40]"
          :page-size.sync="filterinfo.pageSize"
          :current-page.sync="filterinfo.page"
          :total="total"
          @current-change="getCustomerList"
          @size-change="getCustomerList(1)">
        </el-pagination>
      </div>
      <div class="btn_box">
        <el-button class="btn_item" size="small">取消</el-button>
        <el-button class="btn_item" type="primary" size="small">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LaunchAttribution',
  data() {
    return {
      loading: false,
      total: 0,
      formData: {

      },
      multipleSelection: [],
      filterinfo:{
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      },
      tableData:[],
      options: [],
    };
  },
  created() {

  },
  mounted(){
    this.getCustomerList()
  },
  methods: {
    // 点击重置
    resetClick(){
      this.filterinfo = {
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      }
      this.getCustomerList()
    },
    // 获取客户账龄列表
    getCustomerList(num){
      if(num==1){
        this.filterinfo.page = 1
      }
      let params = this.filterinfo
      this.loading = true
      this.$api.pcBillList(params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      });
    },
    handleSortChange({ column, prop, order }) {
      if(order == 'ascending'){
        this.filterinfo.orderBy = prop + " asc"
      }else{
        this.filterinfo.orderBy = prop + " desc"
      }
      this.getCustomerList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    }
  }
};
</script>
<style lang="scss" scoped>
.content_box{
  padding: 20px 24px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin-top: 16px;
  overflow: hidden;

  .top_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #DDDDDD;

    .title{
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;

      &::before{
        content: "";
        display: inline-block;
        width: 6px;
        height: 16px;
        background: #2554E5;
        border-radius: 8px;
        margin-right: 7px;
      }
    }
  }
  .form_box{
    ::v-deep{
      .el-input__inner{
        font-size: 16px;
        color: #222222;
        border-color: #EEEEEE;
        
        &::placeholder{
          color: #A9A9A9 !important;
        }
      }
      .el-input.is-disabled .el-input__inner{
        background-color: #F8F8F9;
      }
      .el-form{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .el-form-item__label{
        padding-bottom: 6px;
        line-height: 22px;
        color: #333333;
      }
    }
    .form_item{
      display: inline-block;
      width: 32.5%;
    }
  }
  .btn_box{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn{
      width: 72px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F0F9FF;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #1A9DFF;
      font-size: 14px;
      color: #1A9DFF;
      margin-left: 8px;
      cursor: pointer;

      &:hover{
        border-color: #337CFF;
        color: #337CFF;
        background: transparent;
      }

      &.delete{
        border-color: #F92B2B;
        color: #F92B2B;
        background: #FFF5F5;

        &:hover{
          border-color: #FF4646;
          color: #FF4646;
          background: transparent;
        }
      }
    }

    .btn_item{
      width: 128px;
      height: 42px;
      font-size: 16px;
    }
  }
}
</style>